import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
export interface ApiError {
  stack: string,
  message: string;
}
@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private toast: ToastrService) { }

  showError(err: any) : void {
    let errorMessage : string = '';
    if (typeof err === 'string' && err?.trim() !== '')
      errorMessage = err;
    else if (typeof err === 'object' && err !== null && err !== undefined) {
      const apiError: ApiError = err;
      if (('stack' in apiError && 'message' in apiError) && (apiError?.message?.trim() !== '' && apiError?.message?.toLowerCase().indexOf('[object') === -1))
        errorMessage = apiError.message;
    }
    this.toast.error(errorMessage || 'An unexpected error occurred, please contact support!');
  }

  showSuccess() : void {
    this.toast.success('Success');
  }
}

